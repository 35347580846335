import React from 'react'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'

export default function VehicleInfo() {
    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Add Vehicle</h5>
                        <div className="row">
                            <div className="col-6">
                                <div className="mb-3">
                                    <label className="form-label">Add Vehicle Image</label>
                                    <input type="file" className="form-control" placeholder="type here" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3">
                                    <label className="form-label">Add Vehicle Name</label>
                                    <input type="text" className="form-control" placeholder="type here" />
                                </div>
                            </div>
                            <div className="col-6">
                                <label className="form-label">Included in package cost*</label>
                                <fieldset className="row mb-3">
                                    <div className="col-sm-10 d-flex gap-10" style={{ gap: "20px" }}>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="yes" />
                                            <label class="form-check-label" for="yes">
                                                Yes
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="no" checked />
                                            <label class="form-check-label" for="no">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary m-2">Submit</button>
                                <button type="submit" className="btn btn-danger">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="table__add">
                            <h5 className="card-title">Manage Vehicles</h5>
                        </div>
                        <div className="search__head">
                            <div className="count">
                                <p>Show</p>
                                <input type="number" className='form-control' value={10} />
                                <p>entries</p>
                            </div>
                            <div className="search">
                                <input type="text" className='form-control' placeholder='Search here' />
                                <i data-feather="search"></i>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table full-title">
                                <thead>
                                    <tr>
                                        <th>Sl.No</th>
                                        <th>Image</th>
                                        <th>Vehicle Name</th>
                                        <th>Included in package cost*</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>001</th>
                                        <td><img src="https://picsum.photos/seed/picsum/200/300" alt="" /></td>
                                        <td>Jane</td>
                                        <td><span className="badge bg-primary">Yes</span></td>
                                        <td className='actions'>
                                            <button className="btn-sm btn-primary"><i data-feather="edit"></i></button>
                                            <button className="btn-sm btn-primary"><i data-feather="trash-2"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="default__pagination">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel={<i data-feather="chevron-right"></i>}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={2}
                                previousLabel={<i data-feather="chevron-left"></i>}
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
