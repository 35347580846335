import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import { toolbarOptions } from '../../constants/config'
import DatePicker from 'react-datepicker'

export default function Seasons() {
    const [startDate, setStartDate] = useState(new Date());
  return (
    <div className="row">
    <div className="col">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Add Season</h5>
          <div className="row">
            <div className="col-12">
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input type="text" className="form-control" placeholder="type here" />
              </div>
            </div>
            <div className="col-4">
              <div className="mb-3">
                <label className="form-label">Icon</label>
                <input type="file" className="form-control" placeholder="type here" />
              </div>
            </div>
            <div className="col-4">
              <div className="mb-3">
                <label className="form-label">Start Date</label>
                <DatePicker className="form-control" selected={startDate} onChange={(date) => setStartDate(date)} />
              </div>
            </div>
            <div className="col-4">
              <div className="mb-3">
                <label className="form-label">End Date</label>
                <DatePicker className="form-control" selected={startDate} onChange={(date) => setStartDate(date)} />
              </div>
            </div>
            <div className="col-12">
              <button type="submit" className="btn btn-primary m-2">Submit</button>
              <button type="submit" className="btn btn-danger">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
