import React from 'react'
import ReactQuill from 'react-quill'
import { toolbarOptions } from '../../../constants/config'

export default function ManageHighlight() {
    return (
        <div className="row">
            <div className="col-12">
                <div className="mb-3">
                    <label className="form-label">Highlight Name</label>
                    <input type="text" className="form-control" placeholder="type here" />
                </div>
            </div>
            <div className="col-12">
                <div className="mb-3">
                    <label className="form-label">Highlight Description</label>
                    <ReactQuill theme="snow" modules={{
                        toolbar: toolbarOptions
                    }}
                        placeholder='type here'
                    />
                </div>
            </div>
            <div className="col-12">
                <button type="submit" className="btn btn-primary m-2">Submit</button>
                <button type="submit" className="btn btn-danger">Cancel</button>
            </div>
        </div>
    )
}
