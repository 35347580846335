import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import feather from 'feather-icons';
import { NavLink, useLocation } from 'react-router-dom';

const SideBar = () => {
    const location = useLocation();
    const [openIndex, setOpenIndex] = useState(null);

    useEffect(() => {
        const sidebar = document.querySelector('.page-sidebar');
        if (sidebar) {
            new PerfectScrollbar(sidebar);
        }
        feather.replace();

        // Set the initial openIndex based on the current URL path
        menuItems.forEach((section, sectionIndex) => {
            section.items.forEach((item, index) => {
                const currentIndex = `${section.id}-${index}`;
                if (item.to === location.pathname || (item.children && item.children.some(child => child.to === location.pathname))) {
                    setOpenIndex(currentIndex);
                }
            });
        });
    }, []); // Only run once on mount

    const handleAccordionClick = (index) => {
        setOpenIndex(prevIndex => (prevIndex === index ? null : index));
    };

    const menuItems = [
        { id: 1, title: 'Main', items: [
            { to: '/', icon: 'layout', label: 'Dashboard' },
            {
                to: '#',
                icon: 'copy',
                label: 'Blogs',
                children: [
                    { to: '/manage-blogs', icon: 'circle', label: 'Manage Blogs' },
                    { to: '/add-blog', icon: 'circle', label: 'Add Blog' }
                ]
            }
        ] },
        
        {
            id: 2, title: 'Sub Menu', items: [
                {
                    to: '#',
                    icon: 'plus-square',
                    label: 'CMS',
                    children: [
                        { to: '/about', icon: 'circle', label: 'About' },
                        { to: '/curated', icon: 'circle', label: 'Curated' }
                    ]
                },
                { to: '/manage-packages', icon: 'briefcase', label: 'Packages' },
                {
                    to: '#',
                    icon: 'map',
                    label: 'Attractions',
                    children: [
                        { to: '/manage-attraction', icon: 'circle', label: 'Manage Attractions' },
                        { to: '/add-attraction', icon: 'circle', label: 'Add Attraction' }
                    ]
                },
                {
                    to: '#',
                    icon: 'activity',
                    label: 'Activities',
                    children: [
                        { to: '/manage-activity', icon: 'circle', label: 'Manage Activities' },
                        { to: '/add-activity', icon: 'circle', label: 'Add Activities' }
                    ]
                },
                {
                    to: '#',
                    icon: 'dollar-sign',
                    label: 'Cost Settings',
                    children: [
                        { to: '/room', icon: 'circle', label: 'Room' },
                        { to: '/vehicle', icon: 'circle', label: 'Vehicle' },
                        { to: '/flight-markup', icon: 'circle', label: 'Markup Settings' }
                    ]
                },
                {
                    to: '#',
                    icon: 'help-circle',
                    label: 'Essentatial Info',
                    children: [
                        { to: '/manage-essential', icon: 'circle', label: 'Manage Essentatial' },
                        { to: '/add-essential', icon: 'circle', label: 'Add FAQ' }
                    ]
                },
                {
                    to: '#',
                    icon: 'target',
                    label: 'Vehicle Info',
                    children: [
                        { to: '/vehicle-info', icon: 'circle', label: 'Vehicle Info' },
                        { to: '/partners', icon: 'circle', label: 'Partners' }
                    ]
                },
                { to: '/questionnaire', icon: 'plus', label: 'Engagement Questionnaire' },

                {
                    to: '#',
                    icon: 'user-check',
                    label: 'User Permissions',
                    children: [
                        { to: '/manage-permission', icon: 'circle', label: 'Manage Permission' },
                        { to: '/add-permission', icon: 'circle', label: 'Add Permission' }
                    ]
                },
                {
                    to: '#',
                    icon: 'image',
                    label: 'Manage Seasons',
                    children: [
                        { to: '/manage-season', icon: 'circle', label: 'Manage Season' },
                        { to: '/add-season', icon: 'circle', label: 'Add Season' }
                    ]
                },
                {
                    to: '#',
                    icon: 'settings',
                    label: 'Settings',
                    children: [{ to: '/manage-inclusions', icon: 'circle', label: 'Manage Inclusions' },{to : "/manage-exclutions",icon: 'circle', label: 'Manage Exclusions'}]
                },
            ]
        }
    ];

    return (
        <div className="page-sidebar">
            <ul className="list-unstyled accordion-menu">
                {menuItems.map((section, sectionIndex) => (
                    <React.Fragment key={section.id}>
                        <li className="sidebar-title">{section.title}</li>
                        {section.items.map((item, index) => {
                            const currentIndex = `${section.id}-${index}`;
                            return (
                                <li key={currentIndex} className={`${openIndex === currentIndex ? 'active-page open' : ''}`}>
                                    <NavLink to={item.to} onClick={() => handleAccordionClick(currentIndex)}>
                                        <i data-feather={item.icon}></i>{item.label}
                                        {item.children && (
                                            <i className="arrow-right" data-feather="chevron-right"></i>
                                        )}
                                    </NavLink>
                                    {item.children && (
                                        <ul className={`submenu ${openIndex === currentIndex ? 'navEffect' : ''}`} style={{ display: openIndex === currentIndex ? 'block' : 'none' }}>
                                            {item.children.map((child, childIndex) => (
                                                <li key={`${currentIndex}-${childIndex}`}>
                                                    <NavLink to={child.to}>
                                                        <i data-feather={child.icon}></i>{child.label}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            );
                        })}
                    </React.Fragment>
                ))}
            </ul>
        </div>
    );
};

export default SideBar;
