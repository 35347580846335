import React from 'react'
import ReactPaginate from 'react-paginate'

export default function Partners() {
  return (
    <div className="row">
    <div className="col">
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Add Partners</h5>
                <div className="row">
                    <div className="col-6">
                        <div className="mb-3">
                            <label className="form-label">Upload logo</label>
                            <input type="file" className="form-control" placeholder="type here" />
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary m-2">Submit</button>
                        <button type="submit" className="btn btn-danger">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-body">
                <div className="table__add">
                    <h5 className="card-title">Manage Partners</h5>
                </div>
                <div className="search__head">
                    <div className="count">
                        <p>Show</p>
                        <input type="number" className='form-control' value={10} />
                        <p>entries</p>
                    </div>
                    <div className="search">
                        <input type="text" className='form-control' placeholder='Search here' />
                        <i data-feather="search"></i>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table full-title">
                        <thead>
                            <tr>
                                <th>Sl.No</th>
                                <th>Partner Logo</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>001</th>
                                <td><img src="https://picsum.photos/seed/picsum/200/300" alt="" /></td>
                                <td className='actions'>
                                    <button className="btn-sm btn-primary"><i data-feather="edit"></i></button>
                                    <button className="btn-sm btn-primary"><i data-feather="trash-2"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="default__pagination">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<i data-feather="chevron-right"></i>}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={2}
                        previousLabel={<i data-feather="chevron-left"></i>}
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
        </div>
    </div>
</div>
  )
}
