import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { donutOptions, lineChartOptions } from '../constants/chartOptions';

export default function Dashboard() {
    const series = [100, 50, 100, 40];
    const lineseries = [
        {
          name: "Complaints",
          data: [28, 29, 33, 36, 32, 32, 33, 34, 36, 38, 35, 40]
        },
        {
          name: "Enquiries",
          data: [12, 11, 14, 18, 17, 13, 13, 15, 16, 17, 18, 20]
        },
        {
            name: "Volunteering",
            data: [30, 22, 56, 36, 32, 23, 33, 44, 34, 38, 4, 36]
          },
          {
            name: "Career",
            data: [8, 44, 10, 2, 11, 55, 2, 22, 16, 3, 18, 55]
          }
    ]
    return (
        <div className="row">
            <div className="col-md-6 col-xl-3">
                <div className="card stat-widget">
                    <div className="card-body">
                        <h5 className="card-title">New Customers</h5>
                        <h2>132</h2>
                        <p>From last week</p>
                        <div className="progress">
                            <div className="progress-bar bg-info progress-bar-striped" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-xl-3">
                <div className="card stat-widget">
                    <div className="card-body">
                        <h5 className="card-title">Orders</h5>
                        <h2>287</h2>
                        <p>Orders in waitlist</p>
                        <div className="progress">
                            <div className="progress-bar bg-success progress-bar-striped" role="progressbar"  aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-xl-3">
                <div className="card stat-widget">
                    <div className="card-body">
                        <h5 className="card-title">Monthly Profit</h5>
                        <h2>7.4K</h2>
                        <p>For last 30 days</p>
                        <div className="progress">
                            <div className="progress-bar bg-danger progress-bar-striped" role="progressbar"  aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-xl-3">
                <div className="card stat-widget">
                    <div className="card-body">
                        <h5 className="card-title">Orders</h5>
                        <h2>87</h2>
                        <p>Orders in waitlist</p>
                        <div className="progress">
                            <div className="progress-bar bg-primary progress-bar-striped" role="progressbar"  aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="card stat-widget">
                    <div className="card-body">
                        <h5 className="card-title">Test Title</h5>
                        <div id="chartjs4">
                            <ReactApexChart options={donutOptions} series={series} type="donut" height={330} />
                        </div>

                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="card stat-widget">
                    <div className="card-body">
                        <h5 className="card-title">Test title</h5>
                        <div id="chartjs4">
                            <ReactApexChart
                                options={lineChartOptions}
                                series={lineseries}
                                type="line"
                                height={290}
                            />

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
