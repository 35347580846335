import React from 'react'
import ReactQuill from 'react-quill'
import { toolbarOptions } from '../../constants/config'

export default function Attractions() {
  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Add Attraction</h5>
            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Title</label>
                  <input type="text" className="form-control" placeholder="type here" />
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">Image</label>
                  <input type="file" className="form-control" placeholder="type here" />
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Short Description</label>
                  <ReactQuill theme="snow" modules={{
                    toolbar: toolbarOptions
                  }}
                    placeholder='type here'
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Long Description</label>
                  <ReactQuill theme="snow" modules={{
                    toolbar: toolbarOptions
                  }}
                    placeholder='type here'
                  />
                </div>
              </div>
              <div className="col-12">
                <button type="submit" className="btn btn-primary m-2">Submit</button>
                <button type="submit" className="btn btn-danger">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
