import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import { toolbarOptions } from '../../constants/config'
import DatePicker from 'react-datepicker'

export default function Blog() {
    const [startDate, setStartDate] = useState(new Date());
  return (
    <div className="row">
    <div className="col">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Add Blog</h5>
          <div className="row">
            <div className="col-12">
              <div className="mb-3">
                <label className="form-label">Title</label>
                <textarea className="form-control" rows={2} placeholder="type here" ></textarea>
              </div>
            </div>
            <div className="col-6">
              <div className="mb-3">
                <label className="form-label">Thumbnail</label>
                <input type="file" className="form-control" placeholder="type here" />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-3">
                <label className="form-label">Date</label>
                <DatePicker className="form-control" selected={startDate} onChange={(date) => setStartDate(date)} />
              </div>
            </div>
            <div className="col-12">
              <div className="mb-3">
                <label className="form-label">Blogs Images/Videos</label>
                <input type="file" className="form-control" placeholder="type here" multiple />
              </div>
            </div>
            <div className="col-12">
              <div className="mb-3">
                <label className="form-label">Short Description</label>
                <ReactQuill theme="snow" modules={{
                  toolbar: toolbarOptions
                }}
                  placeholder='type here'
                />
              </div>
            </div>
            <div className="col-12">
              <div className="mb-3">
                <label className="form-label">Long Description</label>
                <ReactQuill theme="snow" modules={{
                  toolbar: toolbarOptions
                }}
                  placeholder='type here'
                />
              </div>
            </div>
            <div className="col-12">
              <button type="submit" className="btn btn-primary m-2">Submit</button>
              <button type="submit" className="btn btn-danger">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
