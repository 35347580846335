import React from 'react'

export default function Gallery() {
  return (
    <div className="row">
    <div className="col-12">
        <div className="mb-3">
            <label className="form-label">Package Images and Videos.</label>
            <input type="file" className="form-control" placeholder="type here" />
        </div>
    </div>

    <div className="col-12">
        <button type="submit" className="btn btn-primary m-2">Submit</button>
        <button type="submit" className="btn btn-danger">Cancel</button>
    </div>
</div>
  )
}
