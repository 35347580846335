import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from '../pages/Dashboard'
import HotelRooms from '../pages/Cost Settings/HotelRooms'
import FlightMarkup from '../pages/Cost Settings/FlightMarkup'
import Activities from '../pages/Activity/Activities'
import Attractions from '../pages/Attractions/Attractions'
import ManageEssential from '../pages/Essential Info/ManageEssential'
import AddEssential from '../pages/Essential Info/AddEssential'
import ManageActivity from '../pages/Activity/ManageActivity'
import ManageAttraction from '../pages/Attractions/ManageAttraction'
import ManageInclusions from '../pages/Settings/ManageInclusions'
import ManageExclusions from '../pages/Settings/ManageExclusions'
import Accommodation from '../pages/Accommodation'
import Questionnaire from '../pages/Questionnaire'
import AddPermission from '../pages/Permission/AddPermission'
import ManagePermissions from '../pages/Permission/ManagePermissions'
import Blog from '../pages/Blogs/Blog'
import ManageBlogs from '../pages/Blogs/ManageBlogs'
import Vehicle from '../pages/Cost Settings/Vehicle'
import Seasons from '../pages/Seasons/Seasons'
import ManageSeasons from '../pages/Seasons/ManageSeasons'
import About from '../pages/About'
import Layout from '../components/Layout'
import Login from '../pages/Login'
import Curated from '../pages/Curated'
import VehicleInfo from '../pages/VehicleInfo'
import Partners from '../pages/Partners'
import AddDescription from '../pages/Essential Info/AddDescription'
import ManagePackages from '../pages/Packages/ManagePackages'
import AddPackages from '../pages/Packages/AddPackages'


function RouteHandler() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<Layout />} >
        <Route path="/" element={<Dashboard />} />
        <Route path="/room" element={<HotelRooms />} />
        <Route path="/vehicle" element={<Vehicle />} />
        <Route path="/flight-markup" element={<FlightMarkup />} />
        <Route path="/manage-activity" element={<ManageActivity />} />
        <Route path="/add-activity" element={<Activities />} />
        <Route path="/manage-attraction" element={<ManageAttraction />} />
        <Route path="/add-attraction" element={<Attractions />} />
        <Route path="/manage-essential" element={<ManageEssential />} />
        <Route path="/add-description" element={<AddDescription />} />
        <Route path="/add-essential" element={<AddEssential />} />
        <Route path="/manage-inclusions" element={<ManageInclusions />} />
        <Route path="/manage-exclutions" element={<ManageExclusions />} />
        <Route path="/accommodation" element={<Accommodation />} />
        <Route path="/questionnaire" element={<Questionnaire />} />
        <Route path="/add-permission" element={<AddPermission />} />
        <Route path="/manage-permission" element={<ManagePermissions />} />
        <Route path="/add-blog" element={<Blog />} />
        <Route path="/manage-blogs" element={<ManageBlogs />} />
        <Route path="/add-season" element={<Seasons />} />
        <Route path="/manage-season" element={<ManageSeasons />} />
        <Route path="/about" element={<About />} />
        <Route path="/curated" element={<Curated />} />
        <Route path="/vehicle-info" element={<VehicleInfo />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/manage-packages" element={<ManagePackages />} />
        <Route path="/add-package" element={<AddPackages />} />
      </Route>
    </Routes>
  )
}

export default RouteHandler