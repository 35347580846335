import React from 'react'

export default function FlightMarkup() {
    return (
       <>
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Flight Markup Cost</h5>
                        <div className="row">
                            <div className="col-4">
                                <div className="mb-3">
                                    <label for="formGroupExampleInput" className="form-label">Flight Markup Cost(%)</label>
                                    <input type="number" className="form-control" placeholder="type here" />
                                </div>
                            </div>
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary m-2">Submit</button>
                                <button type="submit" className="btn btn-danger">Cancel</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
         <div className="row">
         <div className="col">
             <div className="card">
                 <div className="card-body">
                     <h5 className="card-title">Child Calculation (Percentage) </h5>
                     <div className="row">
                     <div className="col">
                             <div className="mb-3">
                                 <label for="formGroupExampleInput" className="form-label">Age between 5 to 12 (%)</label>
                                 <input type="number" className="form-control" id="formGroupExampleInput" placeholder="type here" />
                             </div>
                         </div>

                         <div className="col">
                             <div className="mb-3">
                                 <label for="formGroupExampleInput" className="form-label">Age between 2 to 5 (%)</label>
                                 <input type="number" className="form-control" id="formGroupExampleInput" placeholder="type here" />
                             </div>
                         </div>
                         
                         <div className="col">
                             <div className="mb-3">
                                 <label for="formGroupExampleInput" className="form-label">Age less than 2 (%)</label>
                                 <input type="number" className="form-control" id="formGroupExampleInput" placeholder="type here" />
                             </div>
                         </div>
                         <div className="col-12">
                             <button type="submit" className="btn btn-primary m-2">Submit</button>
                             <button type="submit" className="btn btn-danger">Cancel</button>
                         </div>
                     </div>
                    
                 </div>
             </div>
         </div>
     </div>
       </>
    )
}
