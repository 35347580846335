import 'bootstrap/dist/css/bootstrap.min.css';


import './App.css';
import Header from './components/Header/Header';
import RouteHandler from './router/router';
import SideBar from './components/Sidebar/SideBar';
import { ToastContainer } from 'react-toastify';
import 'react-quill/dist/quill.snow.css';
import './default.scss';
import { useEffect } from 'react';
import feather from 'feather-icons';
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from 'react-router-dom';
import Login from './pages/Login';
function App() {
  const location = useLocation()
  useEffect(() => {
    feather.replace();
  }, [location?.pathname])
  return (
    <>

      <RouteHandler />

    </>
  );
}

export default App;
