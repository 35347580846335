import React from 'react'
import Select from 'react-select'

export default function ManageAttraction() {
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]
    return (
        <div className="row">
            <div className="col-12">
                <div className="mb-3">
                    <label className="form-label">Choose Attractions</label>
                    <Select options={options} placeholder='Select Attraction' components={{IndicatorSeparator: () => null}} />
                </div>
            </div>
            <div className="col-12">
                <button type="submit" className="btn btn-primary m-2">Submit</button>
                <button type="submit" className="btn btn-danger">Cancel</button>
            </div>
        </div>
    )
}
