import React from 'react'

export default function AddPermission() {
    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Add User</h5>
                        <div className="row">
                            <div className="col-4">
                                <div className="mb-3">
                                    <label className="form-label">Profile Image</label>
                                    <input type="file" className="form-control" placeholder="type here" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="mb-3">
                                    <label className="form-label">User ID</label>
                                    <input type="email" className="form-control" placeholder="type here" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="mb-3">
                                    <label className="form-label">First Name</label>
                                    <input type="text" className="form-control" placeholder="type here" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="mb-3">
                                    <label className="form-label">Last Name</label>
                                    <input type="text" className="form-control" placeholder="type here" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="mb-3">
                                    <label className="form-label">Email</label>
                                    <input type="email" className="form-control" placeholder="type here" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="mb-3">
                                    <label className="form-label">Gender</label>
                                    <div className="permission__check mt-2">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="male" id="male" checked />
                                        <label className="form-check-label" for="male">
                                            Male
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="female" id="flexRadioDfemaleefault2" />
                                        <label className="form-check-label" for="female">
                                           Female
                                        </label>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="mb-3">
                                    <label className="form-label">Phone Number</label>
                                    <input type="email" className="form-control" placeholder="type here" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Add Permission</h5>
                        <div className="row">
                        <div className="col-12">
                                <div className="permission__check mt-2">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="Create" />
                                        <label className="form-check-label" for="Create">
                                            Create Users
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="Settings" />
                                        <label className="form-check-label" for="Settings">
                                            Settings
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="Package" />
                                        <label className="form-check-label" for="Package">
                                            Package
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <button type="submit" className="btn btn-primary m-2">Submit</button>
                                <button type="submit" className="btn btn-danger">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
