import React from 'react'
import ReactQuill from 'react-quill'
import { toolbarOptions } from '../../constants/config'
import { Tab, Tabs } from 'react-bootstrap'
import PackageDetails from './Tabs/PackageDetails'
import ManageOverview from './Tabs/ManageOverview'
import ManageHighlight from './Tabs/ManageHighlight'
import ManageAttraction from './Tabs/ManageAttraction'
import ManageAcivities from './Tabs/ManageAcivities'
import ManageEssential from './Tabs/ManageEssential'
import Gallery from './Tabs/Gallery'

export default function AddPackages() {
    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Add Package </h5>
                        <div className="row">
                            <div className="common__tabs">
                                <Tabs
                                    defaultActiveKey="1"
                                    id="fill-tab-example"
                                    className="mb-3 p-0"
                                >
                                    <Tab eventKey="1" title="Package Details">
                                        <PackageDetails />
                                    </Tab>
                                    <Tab eventKey="2" title="Manage Overview ">
                                        <ManageOverview />
                                    </Tab>
                                    <Tab eventKey="3" title="Manage Highlight">
                                        <ManageHighlight />
                                    </Tab>
                                    <Tab eventKey="4" title="Manage Attractions">
                                        <ManageAttraction />
                                    </Tab>
                                    <Tab eventKey="5" title="Manage Activities">
                                        <ManageAcivities />
                                    </Tab>
                                    <Tab eventKey="6" title="Manage Itenary">
                                        MANAGE ITENARY
                                    </Tab>
                                    <Tab eventKey="7" title="Manage Essentail Information">
                                        <ManageEssential />
                                    </Tab>
                                    <Tab eventKey="8" title="Inclusions">
                                        Inclusion
                                    </Tab>
                                    <Tab eventKey="9" title="Exclusions">
                                        Exclusions
                                    </Tab>
                                    <Tab eventKey="10" title="Gallery">
                                        <Gallery />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">History </h5>
                        <div className="row">
                            
                        </div>
                    </div>
                </div>

        </div>
        </div >
    )
}
