import React from 'react'


export default function Login() {
    return (
        <div id='login-page'>
            {/* <div class='loader'>
                <div class='spinner-grow text-primary' role='status'>
                    <span class='sr-only'></span>
                </div>
            </div> */}
            <div class="container">
                <div class="c-r row justify-content-md-center">
                    <div class="col-md-12 col-lg-4">
                        <div class="card login-box-container">
                            <div class="card-body">
                                <div class="authent-logo">
                                    <img width={100} className='logo_img' src='/assets/img/logo.png' alt="" />
                                </div>
                                <div class="authent-text">
                                    <p>Welcome to SKYWARD</p>
                                    <p>Please Sign-in to your account.</p>
                                </div>

                                <form>
                                    <div class="mb-3">
                                        <div class="form-floating">
                                            <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" />
                                            <label for="floatingInput">Email address</label>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="form-floating">
                                            <input type="password" class="form-control" id="floatingPassword" placeholder="Password" />
                                            <label for="floatingPassword">Password</label>
                                        </div>
                                    </div>
                                    {/* <div class="mb-3 form-check">
                                        <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                        <label class="form-check-label" for="exampleCheck1">Check me out</label>
                                    </div> */}
                                    <div class="d-grid">
                                        <button type="submit" class="btn btn-info m-b-xs">Sign In</button>
                                        {/* <button class="btn btn-primary">Facebook</button> */}
                                    </div>
                                </form>
                                {/* <div class="authent-reg">
                                    <p>Not registered? <a href="register.html">Create an account</a></p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
