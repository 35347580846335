import React from 'react'
import ReactQuill from 'react-quill'
import { toolbarOptions } from '../constants/config'

export default function Curated() {
  return (
    <div className="row">
    <div className="col">
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Add Curated</h5>
                <div className="row">
                <div className="col-12">
                        <div className="mb-3">
                            <label className="form-label">Main Heading</label>
                            <input type="text" className="form-control" placeholder="type here" />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mb-3">
                            <label className="form-label">Main Tagline</label>
                            <textarea className="form-control" rows={2} placeholder="type here" ></textarea>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mb-3">
                            <label className="form-label">Sub Heading</label>
                            <input type="text" className="form-control" placeholder="type here" />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mb-3">
                            <label className="form-label">Short Introduction</label>
                            <textarea className="form-control" rows={2} placeholder="type here" ></textarea>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mb-3">
                            <label className="form-label">Main Introduction</label>
                            <ReactQuill theme="snow" modules={{
                                toolbar: toolbarOptions
                            }}
                                placeholder='type here'
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mb-3">
                            <label className="form-label">Gallery Banner Images</label>
                            <input type="file" className="form-control" placeholder="type here" />
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary m-2">Submit</button>
                        <button type="submit" className="btn btn-danger">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Add Get in touch</h5>
                <div className="row">
                <div className="col-12">
                        <div className="mb-3">
                            <label className="form-label">Title</label>
                            <input type="text" className="form-control" placeholder="type here" />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mb-3">
                            <label className="form-label">Image</label>
                            <input type="file" className="form-control" placeholder="type here" />
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary m-2">Submit</button>
                        <button type="submit" className="btn btn-danger">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
  )
}
