import React from 'react'
import { toolbarOptions } from '../../../constants/config'
import ReactQuill from 'react-quill'

export default function PackageDetails() {
    return (
        <div className="row">
            <div className="col-12">
                <div className="mb-3">
                    <label className="form-label">Enter Package Name</label>
                    <input type="text" className="form-control" placeholder="type here" />
                </div>
            </div>
            <div className="col-4">
                <div className="mb-3">
                    <label className="form-label">Package Duration</label>
                    <input type="date" className="form-control" placeholder="type here" />
                </div>
            </div>
            <div className="col-4">
                <div className="mb-3">
                    <label className="form-label">Package Banner</label>
                    <input type="file" className="form-control" placeholder="type here" />
                </div>
            </div>
            <div className="col-4">
                <div className="mb-3">
                    <label className="form-label">Season</label>
                    <input type="text" className="form-control" placeholder="type here" />
                </div>
            </div>
            <div className="col-12">
                <button type="submit" className="btn btn-primary m-2">Submit</button>
                <button type="submit" className="btn btn-danger">Cancel</button>
            </div>
        </div>
    )
}
