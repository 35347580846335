import React, { useEffect } from 'react'
import ReactQuill from 'react-quill'
import { toolbarOptions } from '../constants/config'
import feather from 'feather-icons';
export default function About() {
    useEffect(() => {
        feather.replace();
    }, [])
    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Add About</h5>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Title</label>
                                    <textarea className="form-control" rows={2} placeholder="type here" ></textarea>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3">
                                    <label className="form-label">Homepage Banner</label>
                                    <input type="file" className="form-control" placeholder="type here" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3">
                                    <label className="form-label">Banner Images (Allow two images, both mandatory)</label>
                                    <input type="file" className="form-control" placeholder="type here" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="features__grid">
                                    <h4>Add Features</h4>
                                    <div className="row align-items-end">
                                        <div className="col-4">
                                            <div className="mb-3">
                                                <label className="form-label">Icon</label>
                                                <input type="file" className="form-control" placeholder="type here" />
                                            </div>
                                        </div>
                                        <div className="col-7">
                                            <div className="mb-3">
                                                <label className="form-label">Title</label>
                                                <input type="text" className="form-control" placeholder="type here" />
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="mb-3">
                                                <button type="submit" className="btn btn-primary"><img style={{ height: "25px" }} src="/assets/img/icons/plus.svg" alt="" /></button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row features_table">
                                        <div className="col">
                                            <table className="table table-hover table-primary">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Sl.No</th>
                                                        <th scope="col">Icon</th>
                                                        <th scope="col">Title</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>1</th>
                                                        <td><img className='feature_icon' src="https://picsum.photos/seed/picsum/200/300" alt="" /></td>
                                                        <td>Reserve with Peace of Mind</td>
                                                        <td><button className="btn-sm btn-primary"><i style={{ width: "16px" }} data-feather="trash-2"></i></button></td>
                                                    </tr>
                                                    <tr>
                                                        <th>2</th>
                                                        <td><img className='feature_icon' src="https://picsum.photos/seed/picsum/200/300" alt="" /></td>
                                                        <td>Reserve with Peace of Mind</td>
                                                        <td><button className="btn-sm btn-primary"><i style={{ width: "16px" }} data-feather="trash-2"></i></button></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> */}
                                </div>
                            </div>


                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Short Description</label>
                                    <ReactQuill theme="snow" modules={{
                                        toolbar: toolbarOptions
                                    }}
                                        placeholder='type here'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Vision</h5>
                        <div className="row">
                            <div className="col-6">
                                <div className="mb-3">
                                    <label className="form-label">Vision Title</label>
                                    <input type="text" className="form-control" placeholder="type here" />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="mb-3">
                                    <label className="form-label">Icon</label>
                                    <input type="file" className="form-control" placeholder="type here" />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="mb-3">
                                    <label className="form-label">Background Image</label>
                                    <input type="file" className="form-control" placeholder="type here" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Short description</label>
                                    <textarea rows={4} className="form-control" placeholder="type here"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Mission</h5>
                        <div className="row">
                            <div className="col-6">
                                <div className="mb-3">
                                    <label className="form-label">Mission Title</label>
                                    <input type="text" className="form-control" placeholder="type here" />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="mb-3">
                                    <label className="form-label">Icon</label>
                                    <input type="file" className="form-control" placeholder="type here" />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="mb-3">
                                    <label className="form-label">Background Image</label>
                                    <input type="file" className="form-control" placeholder="type here" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Short description</label>
                                    <textarea rows={4} className="form-control" placeholder="type here"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Add Main Contents</h5>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Title</label>
                                    <textarea className="form-control" rows={2} placeholder="type here" ></textarea>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Banner Image</label>
                                    <input type="file" className="form-control" placeholder="type here" />
                                </div>
                                {/* <div className="preview__Grid">
                                    <div className="Item">
                                        <div className="close"><img src="/assets/img/icons/close.svg" alt="" /></div>
                                        <img className='main' src="https://picsum.photos/seed/picsum/200/300" alt="" />
                                    </div>
                                </div> */}
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Description</label>
                                    <ReactQuill theme="snow" modules={{
                                        toolbar: toolbarOptions
                                    }}
                                        placeholder='type here'
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary m-2">Submit</button>
                                <button type="submit" className="btn btn-danger">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
