import React, { useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import feather from 'feather-icons';

export default function ManageExclusions() {
    useEffect(() => {
        feather.replace();
    }, [])
    
    return (
        <div className="row">
            <div className="col">
            <div className="card">
                    <div className="card-body">
                        <div className="table__add">
                            <h5 className="card-title">Manage Exclusions</h5>
                        </div>
                        <div className="table__formAdd">
                            <div className="col-md-12">
                                <label className="form-label">Exclusion Name</label>
                                <input type="text" className='form-control' placeholder='type here' />
                            </div>
                            <div className="actions">
                                <button className='btn btn-primary'>Add</button>
                                <button className='btn btn-danger'>Cancel</button>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="table__add">
                            <h5 className="card-title">Manage Exclusions</h5>
                        </div>
                        <div className="search__head">
                            <div className="count">
                                <p>Show</p>
                                <input type="number" className='form-control' value={10} />
                                <p>entries</p>
                            </div>
                            {/* <button className='btn btn-secondary export'>Export <i data-feather="download"></i></button> */}
                            <div className="search">
                                <input type="text" className='form-control' placeholder='Search here' />
                                <i data-feather="search"></i>
                            </div>
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Sl.No</th>
                                    <th scope="col">Exclusion</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>01</th>
                                    <th>Lorem Ipsum is </th>
                                    <td className='actions'>
                                        <button className="btn-sm btn-primary"><i data-feather="edit"></i></button>
                                        <button className="btn-sm btn-primary"><i data-feather="trash-2"></i></button>
                                    </td>
                                </tr>
                                <tr>
                                    <th>02</th>
                                    <th>Lorem Ipsum is </th>
                                    <td className='actions'>
                                        <button className="btn-sm btn-primary"><i data-feather="edit"></i></button>
                                        <button className="btn-sm btn-primary"><i data-feather="trash-2"></i></button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                        <div className="default__pagination">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel={<i data-feather="chevron-right"></i>}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={2}
                                previousLabel={<i data-feather="chevron-left"></i>}
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
