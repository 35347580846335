import React from 'react'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'

export default function ManageSeasons() {
  return (
    <div className="row">
    <div className="col">
        <div className="card">
            <div className="card-body">
                <div className="table__add">
                    <h5 className="card-title">Manage Seasons</h5>
                    <Link to='/add-season'><button className='btn btn-primary'>Add Season</button></Link>
                </div>
                <div className="search__head">
                    <div className="count">
                        <p>Show</p>
                        <input type="number" className='form-control' value={10} />
                        <p>entries</p>
                    </div>
                    <div className="search">
                        <input type="text" className='form-control' placeholder='Search here' />
                        <i data-feather="search"></i>
                    </div>
                </div>
                <div className="table-responsive">
                <table className="table full-title">
                    <thead>
                        <tr>
                            <th>Sl.No</th>
                            <th>Icon</th>
                            <th>Name</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>001</th>
                            <td><img src="https://picsum.photos/seed/picsum/200/300" alt="" /></td>
                            <td>Jane</td>
                            <td>16-05-2024</td>
                            <td>16-10-2024</td>
                            <td className='actions'>
                                <button className="btn-sm btn-primary"><i data-feather="edit"></i></button>
                                <button className="btn-sm btn-primary"><i data-feather="trash-2"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>

                <div className="default__pagination">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<i data-feather="chevron-right"></i>}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={2}
                        previousLabel={<i data-feather="chevron-left"></i>}
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
        </div>
    </div>
</div>
  )
}
