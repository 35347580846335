import React from 'react'
import ReactPaginate from 'react-paginate'

export default function Questionnaire() {
  return (
    <div className="row">
    <div className="col">
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Engagement Questionnaire </h5>
                <div className="row">
                    <div className="col-12">
                        <div className="mb-3">
                            <label className="form-label">Questionnaire</label>
                            <input type="text" placeholder='type here' className='form-control' />
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary m-2">Submit</button>
                        <button type="submit" className="btn btn-danger">Cancel</button>
                    </div>
                </div>

                <div className="search__head">
                            <div className="count">
                                <p>Show</p>
                                <input type="number" className='form-control' value={10} />
                                <p>entries</p>
                            </div>
                            {/* <button className='btn btn-secondary export'>Export <i data-feather="download"></i></button> */}
                            <div className="search">
                                <input type="text" className='form-control' placeholder='Search here' />
                                <i data-feather="search"></i>
                            </div>
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Sl.No</th>
                                    <th scope="col">Questionnaire</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>01</th>
                                    <th>Lorem Ipsum is </th>
                                    <td className='actions'>
                                        <button className="btn-sm btn-primary"><i data-feather="edit"></i></button>
                                        <button className="btn-sm btn-primary"><i data-feather="trash-2"></i></button>
                                    </td>
                                </tr>
                                <tr>
                                    <th>02</th>
                                    <th>Lorem Ipsum is </th>
                                    <td className='actions'>
                                        <button className="btn-sm btn-primary"><i data-feather="edit"></i></button>
                                        <button className="btn-sm btn-primary"><i data-feather="trash-2"></i></button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                        <div className="default__pagination">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel={<i data-feather="chevron-right"></i>}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={2}
                                previousLabel={<i data-feather="chevron-left"></i>}
                                renderOnZeroPageCount={null}
                            />
                        </div>
            </div>
        </div>
    </div>
</div>
  )
}
