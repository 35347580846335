import React, { useState } from 'react';
import Select from 'react-select'
export default function Vehicle() {
    const options = [
        { value: 'Jan', label: 'Jan' },
        { value: 'Feb', label: 'Feb' },
        { value: 'Mar', label: 'Mar' },
        { value: 'Apr', label: 'Apr' },
        { value: 'May', label: 'May' },
        { value: 'Jun', label: 'Jun' },
        { value: 'Jul', label: 'Jul' },
        { value: 'Aug', label: 'Aug' },
        { value: 'Sep', label: 'Sep' },
        { value: 'Oct', label: 'Oct' },
        { value: 'Nov', label: 'Nov' },
        { value: 'Dec', label: 'Dec' }
    ]

    const months = [
        { month: "January" },
        { month: "February" },
        { month: "March" },
        { month: "April" },
        { month: "May" },
        { month: "June" },
        { month: "July" },
        { month: "August" },
        { month: "September" },
        { month: "October" },
        { month: "November" },
        { month: "December" },
    ]
    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Vechicle costing </h5>
                        <div className="row cost__table">
                            <table className="table ">
                                <thead className='table_head'>
                                    <tr>
                                    <th scope="col">Sl.No</th>
                                        <th scope="col">Month</th>
                                        <th scope="col">Vehicle Cost (USD)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        months?.map((ele, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{i+1}</td>
                                                    <td className='name'>{ele?.month}</td>
                                                    <td><input type="number" placeholder='0.00' defaultValue={10.55} className='form-control' /></td>
                                                </tr>

                                            )
                                        })
                                    }

                                </tbody>

                            </table>
                        </div>


                        <div className="col-12">
                            <button type="submit" className="btn btn-primary m-2">Submit</button>
                            <button type="submit" className="btn btn-danger">Cancel</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
