import React from 'react'
import ReactQuill from 'react-quill'
import { toolbarOptions } from '../../../constants/config'

export default function ManageOverview() {
    return (
        <div className="row">

            <div className="col-12">
                <div className="mb-3">
                    <label className="form-label">Overview</label>
                    <ReactQuill theme="snow" modules={{
                        toolbar: toolbarOptions
                    }}
                        placeholder='type here'
                    />
                </div>
            </div>
            <div className="col-12">
                <button type="submit" className="btn btn-primary m-2">Submit</button>
                <button type="submit" className="btn btn-danger">Cancel</button>
            </div>
        </div>
    )
}
